@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply h-full;
    @apply w-full;
    @apply bg-background;
    @apply text-slate-400;
    @apply selection:bg-slate-400;
    @apply selection:text-gray-700;
    @apply scroll-smooth;
  }

  #__next {
    @apply h-full;
    @apply w-full;
    @apply flex;
    @apply flex-col;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-slate-300;
  }

  /** Form elements **/
  label {
    @apply block;
    @apply text-sm;
    @apply font-medium;
    @apply text-slate-400;
  }

  input[type="checkbox"] {
    @apply bg-slate-700;
    @apply border-slate-600;
    @apply focus:ring-transparent;
  }

  textarea,
  input[type="text"],
  input[type="number"],
  input[type="date"],
  input[type="email"],
  input[type="password"] {
    @apply bg-transparent;
    @apply border-slate-700;
    @apply block;
    @apply w-full;
    @apply focus:ring-0;
    @apply focus:border-indigo-500;
    @apply focus:ring-indigo-500;
    @apply sm:text-sm;

    color-scheme: dark;
  }

  textarea:disabled,
  input[type="text"]:disabled,
  input[type="number"]:disabled,
  input[type="date"]:disabled,
  input[type="email"]:disabled,
  input[type="password"]:disabled,
  input[type="radio"]:disabled + label {
    @apply opacity-50;
  }

  textarea {
    min-height: 6rem;
  }

  /** Table **/
  table {
    @apply divide-y;
    @apply divide-slate-800;
  }

  thead {
    @apply bg-background;
  }

  th {
    @apply text-slate-300;
    @apply text-sm;
    @apply text-left;
    @apply font-semibold;

    @apply px-3;
    @apply py-3.5;
    @apply first:pl-4;
    @apply first:pr-3;
    @apply last:pl-3;
    @apply last:pr-4;
  }

  tbody {
    @apply divide-y;
    @apply divide-slate-800;
    @apply bg-background;
  }

  tr.competition-teams {
    background-color: hsl(220, 40%, 8%);
  }

  td {
    @apply text-sm;
    @apply text-slate-500;

    @apply px-3;
    @apply py-4;

    @apply first:pl-4;
    @apply first:pr-3;

    @apply last:pl-3;
    @apply last:pr-4;
  }

  td small,
  td dd {
    @apply text-slate-500;
  }

  /** Tip tap **/
  .prose {
    min-height: 20rem;
  }

  .tiptap-button {
    @apply text-slate-300;
    @apply text-lg;
    @apply p-1;
    @apply capitalize;
  }

  /** Tip tap content **/
  .tiptap-content p {
    @apply mb-4;
  }

  /** Specific elements **/
  @media (min-width: 768px) {
    .teamfinderBg {
      background-image: url("/images/teamfinder.jpg");
      background-position: 10% 50%;
      background-repeat: no-repeat;
      margin: 4rem 0;
    }
  }

  .teamfinderBg:before {
    content: "";
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply inline-block;
    @apply h-full;
    @apply w-full;
    background: linear-gradient(
      90deg,
      hsl(220, 40%, 10%, 1) 20%,
      hsl(220, 40%, 10%, 0.4) 100%
    );
  }
}

@layer components {
  /** Scrollbar **/
  .scrollbar-x {
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 6px;
  }

  .scrollbar-x::-webkit-scrollbar {
    height: 8px;
  }

  .scrollbar-y {
    overflow-y: auto;
    padding-right: 6px;
  }

  .scrollbar-y::-webkit-scrollbar {
    width: 6px;
  }

  .scrollbar-x::-webkit-scrollbar-thumb,
  .scrollbar-y::-webkit-scrollbar-thumb {
    @apply bg-slate-700;
    @apply rounded;
  }

  .no-scrollbar {
    overflow: auto;
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /** Logo preview **/
  .logo-preview {
    @apply border;
    @apply border-slate-700;
    @apply aspect-square;
    @apply overflow-hidden;

    height: 150px;
    width: 150px;
  }

  .label {
    @apply font-medium;
    @apply text-slate-300;
  }

  .defaultTab {
    @apply text-slate-500;
  }
  .defaultActiveText {
    @apply text-slate-300;
  }
  .communityText {
    @apply text-blue-500;
  }
  .communityBg {
    @apply bg-blue-500;
  }
  .associationText {
    @apply text-emerald-500;
  }
  .associationBg {
    @apply bg-emerald-500;
  }
  .organisationText {
    @apply text-rose-500;
  }
  .organisationBg {
    @apply bg-rose-500;
  }
}
